<template>
    <header class="page-title">
        <h1><i class="bi bi-bar-chart-fill"></i> レポート - 日時架電実績</h1>
    </header>

    <!-- 検索欄 -->
    <section class="section">
        <div class="row align-items-end">
            <div class="col-16">
                <label class="form-label">事業部</label>
                <form-select-search-multiple
                    v-model="condition.department_ids"
                    :options="options_department"
                >
                </form-select-search-multiple>
            </div>
            <div class="col-6">
                <label class="form-label">活動者</label>
                <form-select
                    v-model="condition.user_id"
                    :options="options_user"
                    empty_option="全て"
                ></form-select>
            </div>
            <div class="col-8">
                <label class="form-label">集計期間</label>
                <div class="input-group">
                    <form-select
                        v-model="condition.forecast_term"
                        :options="ForecastTermDaily.options()"
                    ></form-select>
                    <form-select
                        v-model="condition.period"
                        :options="options_period"
                        model_type="string"
                        :disabled="condition.forecast_term === ForecastTermDaily.MONTHLY"
                    ></form-select>
                </div>
            </div>
            <div class="col">
                <button @click="search" :disabled="loading_count > 0" class="btn btn-primary">表示</button>
            </div>
        </div>
    </section>

    <section class="section">
        <inline-loader v-if="loading_count > 0"></inline-loader>
        <template v-else>
            <div class="sticky-table">
                <table class="table table-responsive table-bordered table-sm table-hover">
                    <thead>
                        <tr class="table-dark">
                            <th class="text-center"></th>
                            <th class="text-center"></th>
                            <th v-for="label in stats.labels" :key="label" class="text-center col-1">{{ label }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <!-- 反響予定件数 -->
                        <tr class="table-primary">
                            <td class="align-middle text-center">反響予定件数</td>
                            <td class="align-middle text-center"></td>
                            <template v-for="plan, i in stats.plan_for_contact" :key="i">
                                <td class="align-middle text-center" v-if="forecast_term_is_monthly || search_user_id === null">{{ plan }}</td>
                                <td class="align-middle" v-else><input @change="update($event, i, 1)" type="number" class="form-control" :value="plan"></td>
                            </template>
                        </tr>

                        <!-- 反響対応 -->
                        <tr>
                            <td class="align-middle text-center" rowspan="4">反響対応</td>
                            <td class="align-middle text-center">架電</td>
                            <td v-for="item, i in stats.call_for_contact" :key="i" class="align-middle text-center">{{ item.call }}</td>
                        </tr>
                        <tr class="indented">
                            <td class="align-middle text-center">つながった</td>
                            <td v-for="item, i in stats.call_for_contact" :key="i" class="align-middle text-center">{{ item.talk }}</td>
                        </tr>
                        <tr class="indented">
                            <td class="align-middle text-center">メール</td>
                            <td v-for="item, i in stats.call_for_contact" :key="i" class="align-middle text-center">{{ item.mail }}</td>
                        </tr>
                        <tr class="indented">
                            <td class="align-middle text-center text-info">アポ</td>
                            <td v-for="item, i in stats.call_for_contact" :key="i" class="align-middle text-center text-info">{{ item.appointment }}</td>
                        </tr>

                        <!-- 追客予定件数 -->
                        <tr class="table-primary">
                            <td class="align-middle text-center">追客予定件数</td>
                            <td class="align-middle text-center"></td>
                            <template v-for="plan, i in stats.plan_for_follow" :key="i">
                                <td class="align-middle text-center" v-if="forecast_term_is_monthly || search_user_id === null">{{ plan }}</td>
                                <td class="align-middle" v-else><input @change="update($event, i, 2)" type="number" class="form-control" :value="plan"></td>
                            </template>
                        </tr>

                        <!-- 追客 -->
                        <template v-for="row, i in stats.call_for_follow_by_target" :key="i">
                            <tr>
                                <td class="align-middle text-center" rowspan="4">{{ row.target }}</td>
                                <td class="align-middle text-center">架電</td>
                                <td v-for="item, i in row.items" :key="i" class="align-middle text-center">{{ item.call }}</td>
                            </tr>
                            <tr class="indented">
                                <td class="align-middle text-center">つながった</td>
                                <td v-for="item, i in row.items" :key="i" class="align-middle text-center">{{ item.talk }}</td>
                            </tr>
                            <tr class="indented">
                                <td class="align-middle text-center">メール</td>
                                <td v-for="item, i in row.items" :key="i" class="align-middle text-center">{{ item.mail }}</td>
                            </tr>
                            <tr class="indented">
                                <td class="align-middle text-center text-info">アポ</td>
                                <td v-for="item, i in row.items" :key="i" class="align-middle text-center text-info">{{ item.appointment }}</td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </template>
    </section>

</template>

<script>
import InlineLoader from '@/components/tools/InlineLoader.vue';
import FormSelect from '@/components/forms/FormSelect';
import FormSelectSearchMultiple from '@/components/forms/FormSelectSearchMultiple';
import Department from '@/models/entities/department';
import ForecastTermDaily from '@/models/enums/forecast-term-daily';
import Division from '@/models/enums/division';
import User from '@/models/entities/user';

export default {
    name: 'ReportCall',
    components: {
        InlineLoader,
        FormSelect,
        FormSelectSearchMultiple,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showErrorMessage',
        'showMessage',
    ],
    data() {
        return {
            // ローディング
            loading_count: 0,

            // 検索フォーム
            options_department: [],
            options_user: [],
            options_period: [],

            // 検索条件
            condition: {
                department_ids: [],
                user_id: null,
                forecast_term: ForecastTermDaily.MONTHLY,
                period: null,
            },

            ForecastTermDaily,
            Division,

            // 表示用
            stats: [],

            // 月別・日別切り替えフラグ
            forecast_term_is_monthly: true,
            // 検索時活動者
            search_user_id: null,
            search_period: null,
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            this.createPeriods(),

            await Promise.all([
                this.fetchDepartments(),
                this.fetchUsers(),
            ]);

            // 集計期間初期値セット
            this.condition.period = this.options_period[0].value;

            this.search();
        },
        // 事業部マスタ取得
        fetchDepartments() {
            return new Promise(resolve => {
                this.loading_count++;

                this.$http.get('/master/department')
                .then(response => {
                    for (let row of response.data) {
                        let department = new Department(row);
                        this.options_department.push({value: department.department_id, label: department.department_name});
                    }

                    if (this.$store.state.auth.department_id) {
                        // 自身の事業部を設定する
                        this.condition.department_ids.push(this.$store.state.auth.department_id);
                    } else {
                        // 所属する事業部がなければ1つ目の事業部を設定
                        this.condition.department_ids.push(this.options_department[0].value);
                    }
                })
                .finally(() => {
                    this.loading_count--;
                    resolve(true);
                })
            });
        },
        // 活動者取得
        fetchUsers() {
            return new Promise(resolve => {
                this.loading_count++;

                this.$http.get('/user')
                .then(response => {
                    for (let row of response.data) {
                        const user = new User(row);
                        // 部門がアポチームのユーザのみ
                        if (user.division === this.Division.APPOINTMENT_TEAM) {
                            this.options_user.push({value: user.user_id, label: user.user_name});
                        }
                    }
                })
                .finally(() => {
                    this.loading_count--;
                    resolve(true);
                })
            });
        },
        // 集計期間月セレクトボックス作成
        createPeriods() {
            // 表示例 日付降順
            // 2023/03
            // 2023/02

            const from_today = new Date(); // 2023-03-27
            let from = new Date(from_today.setFullYear(from_today.getFullYear() - 1)); // 2022-04-01
            from = new Date(from.setMonth(from_today.getMonth() + 1));
            from = new Date(from.setDate(1));
            const to_today = new Date(); // 2023-03-27
            const to = new Date(to_today.setDate(1)); // 2022-03-01

            for (let date = from; date <= to; date.setMonth(date.getMonth() + 1)) {
                this.options_period.unshift(
                    {
                        value: this.$helper.ymdForInputDate(date),
                        label: this.$helper.ym(date)
                    }
                )
            }
        },

        // 検索
        search() {
            this.loading_count++;
            // 表示データ初期化
            this.stats = [];

            if (this.condition.forecast_term === this.ForecastTermDaily.MONTHLY) {
                // 月別
                this.forecast_term_is_monthly = true;
            } else {
                // 日別
                this.forecast_term_is_monthly = false;
            }

            this.search_user_id = this.condition.user_id;
            this.search_period = this.condition.period;

            this.$http.get('/report/call', {
                params: this.condition,
            })
            .then(response => {
                this.stats = response.data;
            })
            .finally(() => {
                this.loading_count--;
            })
        },

        // 更新処理
        update(event, index, plan_type) {
            this.startScreenLoading();

            this.$http.put(`/report/call`, {
                value: event.target.value,
                day: index + 1,
                plan_type: plan_type,
                user_id: this.search_user_id,
                period: this.search_period,
            })
            .finally(() => {
                this.endScreenLoading();
            })
        }
    }
}
</script>

<style scoped>
input[type="number"] {
    min-width: 50px;
    text-align: center;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance:textfield;
}

.sticky-table {
    max-width: 100%;
    overflow: scroll;
    white-space: nowrap;
}
.sticky-table th {
    width: 55px;
}
.plan-row td input {
    width: 55px;
}
tr:not(.indented) th:nth-child(1),
tr:not(.indented) td:nth-child(1) {
    position: sticky;
    left: 0;
}
tr:not(.indented) th:nth-child(2),
tr:not(.indented) td:nth-child(2),
tr.indented th:nth-child(1),
tr.indented td:nth-child(1) {
    position: sticky;
    left: 100px;
}
tr:not(.table-dark) th:nth-child(-n+2) {
    background-color: #fff;
}
tr:not(.table-primary) td:nth-child(-n+2) {
    background-color: #fff;
}
</style>
