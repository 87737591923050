import Enum from './enum'

/**
 * 日時架電実績 : 集計期間
 */
class ForecastTermDaily extends Enum {
    static MONTHLY = 1; // 月別
    static DAILY = 2; // 日別

    static values() {
        return {
            [this.MONTHLY]: '月別',
            [this.DAILY]: '日別',
        }
    }
}

export default ForecastTermDaily;
